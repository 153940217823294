import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

const initialState = {
  notifications: [],
};

export const slice = createSlice({
  name: 'globalNotifications',
  initialState,
  reducers: {
    hasNotification(state, action) {
      state.notifications = [...state.notifications, ...action.payload];
    },
    resetNotification(state) {
      state.notifications = [];
    },
  },
});

export default slice.reducer;

export function resetNotifications() {
  return () => {
    dispatch(slice.actions.resetNotification());
  };
}
