import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { IconButton, Stack, Typography } from '@mui/material';
// utils
import { fData } from 'utils/formatNumber';
//
import FileThumbnail, { fileData } from 'components/file-thumbnail';
import Iconify from 'components/Iconify';

SingleFilePreview.propTypes = {
  sx: PropTypes.object,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onRemove: PropTypes.func,
  onDownload: PropTypes.func,
  thumbnail: PropTypes.bool,
  imageView: PropTypes.bool,
};

export default function SingleFilePreview({ thumbnail, file, onRemove, imageView = false, onDownload, sx }) {
  if (!file) {
    return null;
  }

  const isNotFormatFile = typeof file === 'string';

  const { name = '', size = 0 } = fileData(file);

  if (thumbnail) {
    return (
      <Stack
        alignItems="center"
        display="inline-flex"
        justifyContent="center"
        sx={{
          m: 0.5,
          width: 80,
          height: 80,
          borderRadius: 1.25,
          overflow: 'hidden',
          position: 'relative',
          border: (theme) => `solid 1px ${theme.palette.divider}`,
          ...sx,
        }}
      >
        <FileThumbnail
          tooltip
          imageView={imageView}
          file={file}
          sx={{ position: 'absolute' }}
          imgSx={{ position: 'absolute' }}
          onDownload={onDownload}
        />

        {onRemove && (
          <IconButton
            size="small"
            onClick={() => onRemove(file)}
            sx={{
              top: 4,
              right: 4,
              p: '1px',
              position: 'absolute',
              color: (theme) => alpha(theme.palette.common.white, 0.72),
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
              '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              },
            }}
          >
            <Iconify icon="eva:close-fill" width={16} />
          </IconButton>
        )}
      </Stack>
    );
  }

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      sx={{
        my: 1,
        px: 1,
        py: 0.75,
        borderRadius: 0.75,
        border: (theme) => `solid 1px ${theme.palette.divider}`,
        ...sx,
      }}
    >
      <FileThumbnail file={file} />

      <Stack flexGrow={1} sx={{ minWidth: 0 }}>
        <Typography variant="subtitle2" noWrap>
          {isNotFormatFile ? file : name}
        </Typography>

        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {isNotFormatFile ? '' : fData(size)}
        </Typography>
      </Stack>

      {onRemove && (
        <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      )}
    </Stack>
  );
}
