import { createSlice } from '@reduxjs/toolkit';
import ArcadaApiService from 'helpers/service/arcadaApiService';
import { FormatErrorResponse } from 'utils/formatErrorResponse';
import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';
import { TABLE_ROWS_COUNT_OPTIONS_MAX } from '../../utils/table';

const initialState = {
  isLoading: false,
  notifications: null,
  error: null,
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getEntitiesSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.isNotificationReceiversLoading = false;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

export function getNotifications(
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  order = 1,
  ordering = 'created_at',
  search,
  filters
) {
  const data = { skip, take, ordering, ordering_desc: order, search, ...filters };

  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await ArcadaApiService.notification.getNotifications(data);
      dispatch(slice.actions.getEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}
