import { createSlice } from '@reduxjs/toolkit';

import ArcadaApiService from 'helpers/service/arcadaApiService';
import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { getFormData } from 'utils/formData';
import { TABLE_ROWS_COUNT_OPTIONS_MAX } from 'utils/table';

import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  isLoading: false,
  partners: null,
  partner: null,
  error: null,
  isCreatePartner: null,
};

const slice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getEntitiesSuccess(state, action) {
      state.isLoading = false;
      state.partners = action.payload;
    },
    getEntitySuccess(state, action) {
      state.isLoading = false;
      state.partner = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    resetEntity(state) {
      state.partner = null;
      state.error = null;
    },
    setIsCreatePartner(state, action) {
      state.isCreatePartner = action.payload;
    },
  },
});

export default slice.reducer;

export function getPartners(skip = 0, take = TABLE_ROWS_COUNT_OPTIONS_MAX[0], ordering = '-name', search, filters) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ArcadaApiService.partners.getPartners({
        skip,
        take,
        ordering,
        search,
        ...filters,
      });
      dispatch(slice.actions.getEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getPartner(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ArcadaApiService.partners.getPartner(id);
      dispatch(slice.actions.getEntitySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getPartnerData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ArcadaApiService.partners.getPartnerData();
      dispatch(slice.actions.getEntitySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function addPartner(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const formData = getFormData(data);

    try {
      const { data } = await ArcadaApiService.partners.addPartner(formData);

      dispatch(slice.actions.getEntitySuccess(data));
      dispatch(slice.actions.hasSuccess());
      dispatch(slice.actions.setIsCreatePartner(true));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Օգտագործողն ավելացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function editPartner(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const formData = getFormData(data);
    try {
      const { data } = await ArcadaApiService.partners.editPartner(formData, id);
      dispatch(slice.actions.getEntitySuccess(data));
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Օգտագործողը թարմացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function deletePartner(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.partners.deletePartner(id);
      dispatch(slice.actions.hasSuccess());
      dispatch(getPartners());
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function togglePartnerStatus(
  id,
  isActive,
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering = '-name',
  search,
  filters
) {
  const formData = getFormData({ is_active: isActive });

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.partners.editPartner(formData, id);
      dispatch(getPartners(skip, take, ordering, search, filters));
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function resetPartner() {
  return () => {
    dispatch(slice.actions.resetEntity());
    dispatch(slice.actions.setIsCreatePartner(null));
  };
}
