import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'redux/store';
import ArcadaApiService from 'helpers/service/arcadaApiService';
import { FormatErrorResponse } from 'utils/formatErrorResponse';
import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  isLoading: true,
  chatSearch: '',
  skipChatRooms: 0,
  roomsWithNewMessages: {},
  rooms: [],
  roomMessages: [],
  activeRoom: null,
  hasNewMessage: false,
  isScrollingMessageList: false,
  isScrollingRoomList: false,
  isTouchedRoomIssueStatus: false,
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setChatSearch(state, action) {
      state.chatSearch = action.payload;
    },
    setSkipChatRooms(state, action) {
      state.skipChatRooms = action.payload;
    },
    setRooms(state, action) {
      if (!state.rooms.length && action.payload.length) {
        state.activeRoom = action.payload[0];
      }
      state.rooms = state.rooms.concat(action.payload.filter((item) => !state.roomsWithNewMessages[item.id]));
    },
    setNewMessageIndicator(state, action) {
      if (!state.rooms.length) return;
      let roomExists = null;
      state.rooms = state.rooms.filter((item) => {
        if (item.id === action.payload.room) {
          item.has_new_message = true;
          roomExists = item;
          return false;
        }
        return item;
      });
      if (!roomExists) {
        state.roomsWithNewMessages[action.payload.room] = true;
        dispatch(getRoom(action.payload.room));
      } else state.rooms = [roomExists, ...state.rooms];
    },
    setRoomIssueStatus(state, action) {
      state.rooms.some(
        (room, index) =>
          room.id === action.payload.roomId && (state.rooms[index].issue.status = action.payload.issueStatus)
      );

      state.activeRoom.issue.status = action.payload.issueStatus;
      state.isTouchedRoomIssueStatus = true;
    },
    setIsTouchedRoomIssueStatus(state, action) {
      state.isTouchedRoomIssueStatus = action.payload;
    },
    addRoom(state, action) {
      state.hasNewMessage = true;
      state.rooms = [...[action.payload], ...state.rooms];
    },
    addRoomMessages(state, action) {
      state.roomMessages = action.payload.concat(state.roomMessages);
    },
    addRoomNewMessage(state, action) {
      if (state.activeRoom?.id === action.payload.room) {
        state.roomMessages.push(action.payload);
      }
    },
    markMessageRead(state, action) {
      state.roomsWithNewMessages[action.payload.room] = true;
      state.activeRoom.has_new_message = false;

      const { rooms } = state;
      for (let i = 0; i < rooms.length; i++) {
        if (rooms[i]?.id === action.payload.room) {
          rooms[i].has_new_message = false;
          break;
        }
      }
      state.hasNewMessage = action.payload.has_new_messages;
    },
    setActiveRoom(state, action) {
      state.activeRoom = action.payload;
    },
    setIsScrollingMessageList(state, action) {
      state.isScrollingMessageList = action.payload;
    },
    setIsScrollingRoomList(state, action) {
      state.isScrollingRoomList = action.payload;
    },
    setHasNewMessage(state, action) {
      state.hasNewMessage = action.payload;
    },
    resetRoomMessages(state) {
      state.roomMessages = [];
      state.activeRoom = null;
    },
    resetForRenderRoomMessages(state) {
      state.roomMessages = [...state.roomMessages];
    },
    resetChatSearch(state) {
      state.chatSearch = '';
    },
    resetRooms(state) {
      state.rooms = [];
    },
    resetChatState(state) {
      state.skipChatRooms = 0;
      state.roomsWithNewMessages = {};
      state.rooms = [];
      state.roomMessages = [];
      state.activeRoom = null;
      state.isScrollingMessageList = false;
      state.isScrollingRoomList = false;
    },
  },
});

export default slice.reducer;

export function getRoom(id) {
  return async () => {
    try {
      const { data } = await ArcadaApiService.chat.getRoom(id);
      dispatch(slice.actions.addRoom(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export const {
  setIsLoading,
  addRoomMessages,
  setActiveRoom,
  setChatSearch,
  setSkipChatRooms,
  setRooms,
  addRoom,
  setIsScrollingMessageList,
  setIsScrollingRoomList,
  hasNewMessage,
  setRoomIssueStatus,
  setIsTouchedRoomIssueStatus,
  addRoomNewMessage,
  setNewMessageIndicator,
  setHasNewMessage,
  markMessageRead,
  resetRoomMessages,
  resetForRenderRoomMessages,
  resetChatSearch,
  resetRooms,
  resetChatState,
} = slice.actions;
