export const TABLE_ROW_BASE_HEIGHT = 72;
export const TABLE_ROWS_COUNT_OPTIONS_MAX = [20, 50, 100, 250, 500];
export const TABLE_ROWS_COUNT_OPTIONS_MIN = [5, 10, 15];

export const emptyRowsMin = (page, count) => Math.max(0, (1 + page) * 6 - count);

export const emptyRowsCount = (entity, page, count, rowsPerPage) => {
  const emptyRows =
    rowsPerPage > entity?.data.length && rowsPerPage <= count ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  return count <= rowsPerPage ? emptyRowsMin(page, count) : emptyRows;
};

export const TABLE_ITEM_MIN_COUNT = 6;
