import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class Resident extends ApiService {
  getResidents(params, config) {
    return this.get(API_ENDPOINTS.user.resident(), params, config);
  }

  getResident(id) {
    return this.get(`${API_ENDPOINTS.user.resident()}${id}/`);
  }

  addResident(data) {
    return this.post(API_ENDPOINTS.user.resident(), data);
  }

  addFamilyMember(data) {
    return this.post(API_ENDPOINTS.user.addFamilyMember(), data);
  }

  addResidentCsv(data) {
    return this.post(API_ENDPOINTS.user.residentCsv(), data);
  }

  editResident(data, id) {
    return this.patch(`${API_ENDPOINTS.user.resident()}${id}/`, data);
  }

  addRealProperties(data) {
    return this.post(API_ENDPOINTS.user.realProperty(), data);
  }

  editRealProperties(data) {
    return this.patch(API_ENDPOINTS.user.realPropertyBulk(), data);
  }

  deleteRealProperties(data, id) {
    return this.delete(`${API_ENDPOINTS.user.realProperty()}${id}/`, data);
  }
}

export default new Resident();
