import { createSlice } from '@reduxjs/toolkit';
import ArcadaApiService from 'helpers/service/arcadaApiService';

import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { TABLE_ROWS_COUNT_OPTIONS_MAX } from 'utils/table';

import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  // properties
  isLoadingProperties: false,
  properties: null,
  errorProperties: null,
  // available properties
  isLoadingAvailableProperties: false,
  availableProperties: null,
  errorAvailableProperties: null,
  // property
  isLoadingProperty: false,
  property: null,
  errorProperty: null,
};

const slice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    // properties
    startLoadingProperties(state) {
      state.isLoadingProperties = true;
    },
    hasSuccessProperties(state, action) {
      state.isLoadingProperties = false;
      state.errorProperties = false;
      state.properties = action.payload;
    },
    hasErrorProperties(state, action) {
      state.isLoadingProperties = false;
      state.errorProperties = action.payload;
    },
    // available properties
    startLoadingAvailableProperties(state) {
      state.isLoadingAvailableProperties = true;
    },
    hasSuccessAvailableProperties(state, action) {
      state.isLoadingAvailableProperties = false;
      state.errorAvailableProperties = false;
      state.availableProperties = action.payload;
    },
    hasErrorAvailableProperties(state, action) {
      state.isLoadingAvailableProperties = false;
      state.errorAvailableProperties = action.payload;
    },
    // property
    startLoadingProperty(state) {
      state.isLoadingProperty = true;
    },
    hasSuccessProperty(state, action) {
      state.isLoadingProperty = false;
      state.errorProperty = false;
      state.property = action.payload;
    },
    hasErrorProperty(state, action) {
      state.isLoadingProperty = false;
      state.errorProperty = action.payload;
    },
    resetProperty(state) {
      state.property = null;
      state.errorProperty = null;
    },
  },
});

export default slice.reducer;

export function getProperties(skip = 0, take = TABLE_ROWS_COUNT_OPTIONS_MAX[0], ordering = 'number', search, filters) {
  return async () => {
    dispatch(slice.actions.startLoadingProperties());
    try {
      const { data } = await ArcadaApiService.property.getProperties({
        skip,
        take,
        ordering,
        search,
        ...filters,
      });
      dispatch(slice.actions.hasSuccessProperties(data));
    } catch (error) {
      dispatch(slice.actions.hasErrorProperties(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getListOfProperties() {
  return async () => {
    dispatch(slice.actions.startLoadingProperties());
    try {
      const { data } = await ArcadaApiService.property.getListOfProperties();
      dispatch(slice.actions.hasSuccessProperties(data));
    } catch (error) {
      dispatch(slice.actions.hasErrorProperties(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getListOfAvailableProperties() {
  return async () => {
    dispatch(slice.actions.startLoadingAvailableProperties());
    try {
      const { data } = await ArcadaApiService.property.getListOfAvailableProperties();
      dispatch(slice.actions.hasSuccessAvailableProperties(data));
    } catch (error) {
      dispatch(slice.actions.hasErrorAvailableProperties(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getProperty(id) {
  return async () => {
    dispatch(slice.actions.startLoadingProperty());
    try {
      const { data } = await ArcadaApiService.property.getProperty(id);
      dispatch(slice.actions.hasSuccessProperty(data));
    } catch (error) {
      dispatch(slice.actions.hasErrorProperty(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function addProperty(payload) {
  return async () => {
    dispatch(slice.actions.startLoadingProperty());
    try {
      const { data } = await ArcadaApiService.property.addProperty(payload);
      dispatch(slice.actions.hasSuccessProperty(data));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Գույքն ավելացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasErrorProperty(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function editProperty(payload, id) {
  return async () => {
    dispatch(slice.actions.startLoadingProperty());
    try {
      const { data } = await ArcadaApiService.property.editProperty(payload, id);
      dispatch(slice.actions.hasSuccessProperty(data));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Գույքը թարմացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasErrorProperty(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function deleteProperties(data, id) {
  return async () => {
    dispatch(slice.actions.startLoadingProperties());
    try {
      await ArcadaApiService.property.deleteProperties(data, id);
      dispatch(slice.actions.hasSuccessProperties());
    } catch (error) {
      dispatch(slice.actions.hasErrorProperties(error));
      dispatch(
        globalNotificationSlice.actions.hasNotification(FormatErrorResponse({ message: ['Ինչ որ բան սխալ է'] }))
      );
    }
  };
}

export function resetProperty() {
  return () => {
    dispatch(slice.actions.resetProperty());
  };
}
