import { createSlice } from '@reduxjs/toolkit';

import ArcadaApiService from 'helpers/service/arcadaApiService';
import { isObjectInstance } from 'utils/checkTypes';
import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { getFormData } from 'utils/formData';
import { TABLE_ROWS_COUNT_OPTIONS_MAX } from 'utils/table';

import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  isLoading: false,
  saleRealEstates: null,
  saleRealEstate: null,
  error: null,
  secondaryImages: null,
  attachmentFiles: null
};

const slice = createSlice({
  name: 'saleRealEstate',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getEntitiesSuccess(state, action) {
      state.isLoading = false;
      state.saleRealEstates = action.payload;
    },
    getEntitySuccess(state, action) {
      state.isLoading = false;
      state.saleRealEstate = action.payload;
      state.secondaryImages = action.payload?.files.filter((item) => !item.is_default);
      state.attachmentFiles = action.payload?.attachment_files;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasSuccess(state) {
      state.isLoading = false;
      state.error = false;
    },
    resetEntity(state) {
      state.saleRealEstate = null;
      state.secondaryImages = null;
      state.attachmentFiles = null;
      state.error = null;
    },
    setSecondaryImages(state, action) {
      state.secondaryImages = [...(state.secondaryImages || []), ...action.payload];
    },
    deleteSecondaryImages(state, action) {
      state.secondaryImages = action.payload;
    },
    addAttachments(state, action) {
      state.attachmentFiles = [...(state.attachmentFiles || []), ...action.payload];
    },
    deleteAttachments(state, action) {
      state.attachmentFiles = action.payload;
    },
  },
});

export default slice.reducer;

export const { setSecondaryImages, deleteSecondaryImages, addAttachments, deleteAttachments } = slice.actions;

export function getSaleRealEstates(
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering,
  search,
  filters
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await ArcadaApiService.saleRealEstate.getSaleRealEstates({
        skip,
        take,
        ordering,
        search,
        ...filters,
      });
      dispatch(slice.actions.getEntitiesSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getSaleRealEstate(id) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const { data } = await ArcadaApiService.saleRealEstate.getSaleRealEstate(id);
      dispatch(slice.actions.getEntitySuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function addSaleRealEstate({ realProperty }, dirtyData, secondaryImages, attachments) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const data = { ...realProperty };
      const formData = getFormData(data);
      const { coverImage } = dirtyData || {};
      const { data: result } = await ArcadaApiService.saleRealEstate.addSaleRealEstate(formData);
      if (coverImage) {
        dispatch(
          addSaleRealEstateFiles(
            getFormData({
              image: coverImage,
              type: 1,
              is_default: true,
              real_estate_service: result?.id,
            })
          )
        );
      }
      if (secondaryImages?.length) {
        secondaryImages.forEach((image) => {
          dispatch(
            addSaleRealEstateFiles(
              getFormData({
                image,
                type: 1,
                is_default: false,
                real_estate_service: result?.id,
              })
            )
          );
        });
      }

      if (attachments?.length) {
        attachments.forEach((file) => {
          dispatch(
              addSaleRealEstateAttachments(
                  getFormData({
                    file,
                    real_estate_service: result?.id,
                  })
              )
          );
        });
      }

      dispatch(slice.actions.hasSuccess());
      dispatch(slice.actions.getEntitySuccess(data));
      dispatch(
        globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Հայտարարությունն ավելացված է']))
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function editSaleRealEstate({ realProperty }, dirtyData, currentSaleRealEstate, secondaryImages, attachments) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { id, is_active: isActive } = currentSaleRealEstate || {};
      if (dirtyData?.price) dirtyData.price = dirtyData.price.replaceAll(',', '');

      const { coverImage, imageDeleteIds, attachmentDeleteIds, ...rest } = dirtyData || {};
      const formData = getFormData({...realProperty, is_active: isActive, ...rest });
      if (imageDeleteIds?.filter((id) => id).length)
        await ArcadaApiService.saleRealEstate.bulkDeleteSaleRealEstateFile({ ids: imageDeleteIds });

      if (attachmentDeleteIds?.filter((id) => id).length)
        await ArcadaApiService.saleRealEstate.bulkDeleteSaleRealEstateAttachment({ ids: attachmentDeleteIds });

      await ArcadaApiService.saleRealEstate.editSaleRealEstate(formData, id);
      if (isObjectInstance(coverImage)) {
        dispatch(
          addSaleRealEstateFiles(
            getFormData({
              image: coverImage,
              type: 1,
              is_default: true,
              real_estate_service: id,
            })
          )
        );
      }
      if (secondaryImages?.length) {
        secondaryImages.forEach((image) => {
          if (image?.id) return;
          dispatch(
            addSaleRealEstateFiles(
              getFormData({
                image,
                type: 1,
                is_default: false,
                real_estate_service: id,
              })
            )
          );
        });
      }

      if (attachments?.length) {
        attachments.forEach((file) => {
          if (typeof file !== "object" || file?.id) return;
          dispatch(
              addSaleRealEstateAttachments(
                  getFormData({
                    file,
                    real_estate_service: id,
                  })
              )
          );
        });
      }

      dispatch(slice.actions.hasSuccess());
      dispatch(
        globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Հայտարարությունը թարմացված է']))
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function addSaleRealEstateFiles(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.saleRealEstate.addSaleRealEstateFiles(data);
      dispatch(slice.actions.hasSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(
        globalNotificationSlice.actions.hasNotification(FormatErrorResponse({ message: ['Ինչ որ բան սխալ է'] }))
      );
    }
  };
}

export function addSaleRealEstateAttachments(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.saleRealEstate.addSaleRealEstateAttachments(data);
      dispatch(slice.actions.hasSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(
          globalNotificationSlice.actions.hasNotification(FormatErrorResponse({ message: ['Ինչ որ բան սխալ է'] }))
      );
    }
  };
}

export function resetSaleRealEstate() {
  return () => {
    dispatch(slice.actions.resetEntity());
  };
}
