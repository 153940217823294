import { createSlice } from '@reduxjs/toolkit';
import ArcadaApiService from 'helpers/service/arcadaApiService';
import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';
import { getFormData } from '../../utils/formData';
import { TABLE_ROWS_COUNT_OPTIONS_MAX } from '../../utils/table';

const initialState = {
  isLoading: false,
  posts: null,
  post: null,
  error: null,
  attachments: null,
};

const slice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getEntitiesSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },
    getEntitySuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
      state.attachments = action.payload?.files;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    resetEntity(state) {
      state.post = null;
      state.attachments = null;
      state.error = null;
    },
    addAttachments(state, action) {
      state.attachments = [...(state.attachments || []), ...action.payload];
    },
    deleteAttachments(state, action) {
      state.attachments = action.payload;
    },
  },
});

export default slice.reducer;

export const { addAttachments, deleteAttachments } = slice.actions;

export function getPosts(
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  search,
  status,
  order = 1,
  ordering = 'name'
) {
  const data = { skip, take, ordering, ordering_desc: order };

  if (search) data.search = search.trim();
  if (status) data.status = status;

  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await ArcadaApiService.post.getPosts(data);
      dispatch(slice.actions.getEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getPost(id) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await ArcadaApiService.post.getPost(id);
      dispatch(slice.actions.getEntitySuccess(response.data));
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPost(data, attachments) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // eslint-disable-next-line no-unused-vars
    const { files, fileDeleteIds, ...other } = data || {};
    const formData = getFormData(other);

    try {
      const { data: newData } = await ArcadaApiService.post.addPost(formData);
      if (attachments?.length) {
        attachments.forEach(async (item) => {
          if (item?.id) return;
          const fileFormData = getFormData();
          fileFormData.append('file', item);
          fileFormData.set('post', newData?.id);
          await ArcadaApiService.post.addPostAttachments(fileFormData);
        });
      }

      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Հրապարակումն ավելացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function editPost(data, id, attachments) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // eslint-disable-next-line no-unused-vars
    const { files, fileDeleteIds, ...other } = data || {};
    const formData = getFormData(other);

    try {
      const deleteFileIds = data.fileDeleteIds?.filter((id) => id);
      if (deleteFileIds?.length) await ArcadaApiService.post.bulkDeletePostAttachments({ ids: deleteFileIds });

      await ArcadaApiService.post.editPost(formData, id);

      if (attachments?.length) {
        attachments.forEach(async (item) => {
          if (item?.id) return;
          const fileFormData = getFormData();
          fileFormData.append('file', item);
          fileFormData.set('post', id);
          await ArcadaApiService.post.addPostAttachments(fileFormData);
        });
      }
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Հրապարակումը թարմացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function togglePostStatus(
  id,
  status,
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  search,
  statusFilter,
  order = 0,
  ordering = 'name'
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const formData = getFormData({ status });
    try {
      await ArcadaApiService.post.togglePostStatus(formData, id);
      dispatch(getPosts(skip, take, search, statusFilter, order, ordering));
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function resetPost() {
  return () => {
    dispatch(slice.actions.resetEntity());
  };
}
