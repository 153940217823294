export const USER_ROLES = {
  SUPER_ADMIN: 1,
  MANAGING_ADMIN: 2,
  SERVICE_COMPANY: 3,
  RESIDENT: 4,
  NON_RESIDENT: 5,
  PARTNER: 6,
};

export const AUTHENTICATED_USERS_NUMBERING = [1, 2, 3, 6];

export const AUTHENTICATED_USERS_WITH_CHAT_ACCESS_NUMBERING = [1, 2, 3];

export const USER_ROLES_NAMING = {
  1: 'Ադմին',
  2: 'Ադմին',
  3: 'Համատիրություն',
  4: 'Բնակիչ',
  5: 'Օգտատեր',
};

export const USER_STATUS_COLORS = {
  active: 'success',
  pending: 'error',
  deactivated: 'default',
};

export const USER_STATUS_NAMING = {
  active: 'Ակտիվ',
  pending: 'Չհաստատված',
  deactivated: 'Ապաակտիվացված',
};

export const USER_FIRST_NAME_SIZE = 30;

export const USER_LAST_NAME_SIZE = 50;

export const USER_EMAIL_SIZE = 100;

export const USER_PHONE_SIZE = 50;
