import { createSlice } from '@reduxjs/toolkit';

import ArcadaApiService from 'helpers/service/arcadaApiService';
import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { getFormData } from 'utils/formData';
import { TABLE_ROWS_COUNT_OPTIONS_MAX } from 'utils/table';

import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  isLoading: false,
  users: null,
  user: null,
  error: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getEntitiesSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },
    getEntitySuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    resetEntity(state) {
      state.user = null;
      state.error = null;
    },
  },
});

export default slice.reducer;

export function getUsers(skip = 0, take = TABLE_ROWS_COUNT_OPTIONS_MAX[0], ordering = 'first_name', order = 1) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ArcadaApiService.user.getUsers({ skip, take, ordering, ordering_desc: order });
      dispatch(slice.actions.getEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getUser(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ArcadaApiService.user.getUser(id);
      dispatch(slice.actions.getEntitySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getUserByUID(uid) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ArcadaApiService.user.getUserByUID(uid);
      dispatch(slice.actions.getEntitySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function addUser(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const formData = getFormData(data);

    try {
      await ArcadaApiService.user.addUser(formData);
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Օգտագործողն ավելացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function editUser(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const formData = getFormData(data);
    try {
      await ArcadaApiService.user.editUser(formData, id);
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Օգտագործողը թարմացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function toggleUserStatus(
  id,
  isActive,
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering = 'first_name',
  order = 0
) {
  const formData = getFormData({ is_active: isActive });

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.user.toggleUserStatus(formData, id);
      dispatch(getUsers(skip, take, ordering, order));
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function resetUser() {
  return () => {
    dispatch(slice.actions.resetEntity());
  };
}
