import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class Post extends ApiService {
  getPosts(params) {
    return this.get(API_ENDPOINTS.post.root, params);
  }

  getPost(id) {
    return this.get(`${API_ENDPOINTS.post.root}${id}/`);
  }

  addPost(data) {
    return this.post(API_ENDPOINTS.post.root, data);
  }

  editPost(data, id) {
    return this.patch(`${API_ENDPOINTS.post.root}${id}/`, data);
  }

  togglePostStatus(data, id) {
    return this.patch(`${API_ENDPOINTS.post.root}${id}/`, data);
  }

  addPostAttachments(params) {
    return this.post(API_ENDPOINTS.post.attachments.root, params);
  }

  bulkDeletePostAttachments(params) {
    return this.post(API_ENDPOINTS.post.attachments.bulkDeletePostAttachments(), params);
  }
}

export default new Post();
