import PropTypes from 'prop-types';

import { useFormContext, Controller } from 'react-hook-form';

import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { InputSelectIcon } from 'theme/overrides/CustomIcons';

ControlledAutocompleteTextField.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  label: PropTypes.string,
  noOptionsText: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  hasInput: PropTypes.bool,
  sx: PropTypes.object,
  onChange: PropTypes.func,
};

export default function ControlledAutocompleteTextField({
  name,
  defaultValue,
  value: fieldValue,
  label,
  options,
  disabled,
  hasInput,
  onChange,
  noOptionsText,
  sx,
  ...other
}) {
  const { control } = useFormContext();

  const fieldInputChange = (newValue, field) => {
    if (typeof newValue === 'string') field.onChange(newValue, field.id);
  };

  const fieldChange = (newValue, field) => {
    if (typeof newValue === 'object' && newValue !== null) field.onChange(newValue.name);
    onChange(newValue);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Autocomplete
          sx={{ mx: 1, mb: 3, ...sx }}
          {...field}
          noOptionsText={noOptionsText}
          freeSolo={hasInput}
          disableClearable
          value={fieldValue}
          disabled={disabled}
          onInputChange={(_, newValue) => fieldInputChange(newValue, field)}
          onChange={(_, newValue) => fieldChange(newValue, field)}
          options={options.map((option) => option)}
          getOptionLabel={(option) => {
            if (typeof option === 'string') return option;
            return option?.name || option?.number || '';
          }}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.id} sx={{ whiteSpace: 'normal' }}>
              {option.name || option.number}
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              required
              error={!!error}
              helperText={error?.message}
              inputRef={ref}
              inputProps={{
                ...params.inputProps,
                onKeyDown: (e) => {
                  if (e.key === 'Enter') e.stopPropagation();
                },
              }}
              {...other}
            />
          )}
          popupIcon={
            <InputSelectIcon
              style={{
                right: 0,
                fontSize: 16,
                position: 'static',
                pointerEvents: 'none',
              }}
            />
          }
        />
      )}
    />
  );
}
