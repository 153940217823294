import { createSlice } from '@reduxjs/toolkit';

import ArcadaApiService from 'helpers/service/arcadaApiService';
import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { getFormData } from 'utils/formData';
import { TABLE_ROWS_COUNT_OPTIONS_MAX } from 'utils/table';

import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  isLoadingList: false,
  isLoading: false,
  partnerDeals: null,
  partnerDeal: null,
  error: null,
};

const slice = createSlice({
  name: 'partnerDeals',
  initialState,
  reducers: {
    startLoadingList(state) {
      state.isLoadingList = true;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    getEntitiesSuccess(state, action) {
      state.isLoading = false;
      state.partnerDeals = action.payload;
    },
    getEntitySuccess(state, action) {
      state.isLoadingList = false;
      state.isLoading = false;
      state.partnerDeal = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasSuccess(state) {
      state.isLoadingList = false;
      state.isLoading = false;
      state.error = null;
    },
    resetEntity(state) {
      state.partnerDeal = null;
      state.error = null;
    },
  },
});

export default slice.reducer;

export function getPartnerDeals(
  partnerID,
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering = '-name',
  search,
  filters
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ArcadaApiService.partnerDeals.getPartnerDeals({
        partner: partnerID,
        skip,
        take,
        ordering,
        search,
        ...filters,
      });
      dispatch(slice.actions.getEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getPartnerDeal(id) {
  return async () => {
    dispatch(slice.actions.startLoadingList());
    try {
      const response = await ArcadaApiService.partnerDeals.getPartnerDeal(id);
      dispatch(slice.actions.getEntitySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function addPartnerDeal(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const formData = getFormData(data);

    try {
      await ArcadaApiService.partnerDeals.addPartnerDeal(formData);
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Գործարքն ավելացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function editPartnerDeal(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const formData = getFormData(data);
    try {
      await ArcadaApiService.partnerDeals.editPartnerDeal(formData, id);
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Գործարքը թարմացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function deletePartnerDeal(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.partnerDeals.deletePartnerDeal(id);
      dispatch(slice.actions.hasSuccess());
      dispatch(getPartnerDeals());
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function resetPartnerDeal() {
  return () => {
    dispatch(slice.actions.resetEntity());
  };
}
