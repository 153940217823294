export const ISSUE_STATUS = {
  OPEN: 1,
  CLOSED: 2,
};

export const ISSUE_STATUS_NAMING = {
  0: 'Բոլորը',
  1: 'Բաց հայտեր',
  2: 'Փակված հայտեր',
};

export const ISSUE_TYPE_NAME_SIZE = 50;
