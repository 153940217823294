import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import useCollapseDrawer from 'hooks/useCollapseDrawer';
import { setNewMessageIndicator, setHasNewMessage } from 'redux/slices/chat';
import { getCallRequestOpenCount } from 'redux/slices/callRequest';
import { dispatch, store } from 'redux/store';

import { HEADER, NAVBAR } from 'config';
import ChatSocket from 'helpers/service/chatSocketService';
import { ISSUE_STATUS } from 'helpers/constants/issue';

import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

const chatSocket = ChatSocket.getInstance();

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { user } = useAuth();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    chatSocket
      .listen('has_new_messages', 'dashboard_has_new_messages', ({ data }) => dispatch(setHasNewMessage(data)))
      .listen('new_message', 'dashboard_new_message', (data) => {
        dispatch(setHasNewMessage(true));
        const state = store.getState();
        const { issueStatus, issueProject } = state.issue;

        if (
          user.id !== data.user.id &&
          (!issueProject || issueProject === data?.issue?.project) &&
          issueStatus !== ISSUE_STATUS.CLOSED
        )
          dispatch(setNewMessageIndicator(data));
      })
      .listen('message_read', 'dashboard_message_read', (data) => dispatch(setHasNewMessage(data.has_new_messages)));

    dispatch(getCallRequestOpenCount());
  }, []);

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
