import {Box} from '@mui/material';

export default function InfoIcon({...other}) {
    return (
        <Box {...other}>
            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
                    fill="#FFBE5C"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M12 18.875C8.26053 18.875 5.22913 15.797 5.22913 12C5.22913 8.20304 8.26053 5.125 12 5.125C15.7394 5.125 18.7708 8.20304 18.7708 12C18.7708 15.797 15.7394 18.875 12 18.875ZM12 6.5C9.00842 6.5 6.58329 8.96243 6.58329 12C6.58329 15.0376 9.00842 17.5 12 17.5C14.9915 17.5 17.4166 15.0376 17.4166 12C17.4166 8.96243 14.9915 6.5 12 6.5ZM12 11.1406C12.4674 11.1406 12.8463 11.5254 12.8463 12V14.75C12.8463 15.2246 12.4674 15.6094 12 15.6094C11.5325 15.6094 11.1536 15.2246 11.1536 14.75V12C11.1536 11.5254 11.5325 11.1406 12 11.1406ZM12 8.5625C11.5325 8.5625 11.1536 8.94726 11.1536 9.42188C11.1536 9.89649 11.5325 10.2812 12 10.2812C12.4674 10.2812 12.8463 9.89649 12.8463 9.42188C12.8463 8.94726 12.4674 8.5625 12 8.5625Z"
                      fill="white"/>
            </svg>
        </Box>
    );
}
