import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class Project extends ApiService {
  getProjects() {
    return this.get(API_ENDPOINTS.project.root);
  }

  getProject(id) {
    return this.get(`${API_ENDPOINTS.project.root}${id}/`);
  }

  addProject(data) {
    return this.post(API_ENDPOINTS.project.root, data);
  }

  editProject(data, id) {
    return this.patch(`${API_ENDPOINTS.project.root}${id}/`, data);
  }

  getProjectStructure() {
    return this.get(API_ENDPOINTS.project.structure());
  }

  getProjectApartments(data) {
    return this.get(API_ENDPOINTS.project.apartments(), data);
  }
}

export default new Project();
