import {Box} from '@mui/material';

export default function PromotionalIcon({...other}) {
    return (
        <Box {...other}>
            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
                    fill="#00B5EE" fillOpacity="0.75"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M5.7915 8.93404C6.14462 8.65968 6.59857 8.58504 7.01152 8.73341L8.63889 9.31814L17.0327 6.64325C17.4423 6.51272 17.885 6.59814 18.2271 6.8737C18.5693 7.14926 18.7708 7.58266 18.7708 8.04305V15.3415C18.7708 15.8076 18.5643 16.2456 18.2151 16.5204C17.866 16.7953 17.416 16.8738 17.0042 16.7319L13.3541 15.4738V15.957C13.3541 16.7631 12.7478 17.4167 11.9999 17.4167H10.6458C9.89789 17.4167 9.29161 16.7631 9.29161 15.957V14.0736L8.62329 13.8433L7.01152 14.4224C6.59857 14.5708 6.14462 14.4961 5.7915 14.2218C5.43837 13.9474 5.22913 13.5068 5.22913 13.0376V10.1182C5.22913 9.64899 5.43837 9.20839 5.7915 8.93404ZM10.6458 14.5403L11.9999 15.0071V15.957H10.6458V14.5403ZM17.4166 8.04305L16.0625 8.47459L9.19507 10.6631V12.5078L16.0625 14.8748L17.4166 15.3415V13.809V9.5652V8.04305ZM7.8409 12.5857V10.5701L6.58329 10.1182V11.4989V11.6569V13.0376L7.8409 12.5857Z"
                      fill="white"/>
            </svg>
        </Box>
    );
}
