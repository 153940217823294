import * as Yup from 'yup';

export const PASSWORD_REG_EXP =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&~`_^|'=[\]")\-(+}{};:.,></])[A-Za-z\d@$!%*#?&~`_^|'=[\]")\-(+}{};:.,></]{8,}$/;

export const EMAIL_REG_EXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const fieldTypeValidaiton = (value, size) => {
  switch (typeof value) {
    case 'object':
      return Yup.object().required('Պարտադիր դաշտ');
    default:
      return Yup.string()
        .required('Պարտադիր դաշտ')
        .test('field', 'Այս դաշտը չի կարող դատարկ լինել', (value) => value.trim())
        .test('length', `Մուտքագրեք ոչ ավել, քան ${size} նիշ`, (value) => value.length <= size);
  }
};

export const ErrorTypeValidationText = (errorType) => {
  switch (errorType) {
    case 'file-too-large':
      return 'Կցված ֆայլը շատ մեծ է';
    case 'file-invalid-type':
      return 'Կցված ֆայլը չի համապատասխանում ֆորմատին';
    default:
      return 'Ինչ որ բան սխալ է';
  }
};

export const setResidentFormErrorMessages = (errors, setError) => {
  if (errors.real_properties) {
    errors.real_properties.forEach((error, index) =>
      Object.entries(error).forEach((entry) =>
        setError(`realProperties[${index}].${entry[0]}`, { type: 'manual', message: entry[1] })
      )
    );
    return;
  }
  if (errors instanceof Object) {
    Object.entries(errors).forEach((entry) =>
      setError(`personalDetails.${entry[0]}`, { type: 'manual', message: entry[1] })
    );
  }
};

export const setSaleRealEstateFormErrorMessages = (errors, setError) => {
  Object.entries(errors).forEach((error) =>
    setError(`realProperty.${error[0]}`, { type: 'manual', message: error[1] })
  );
};

export const isNegativeNumber = (value) => value.toLocaleString()[0] === '-';
