import { createSlice } from '@reduxjs/toolkit';

import { FormatErrorResponse } from 'utils/formatErrorResponse';

import { dispatch } from '../store';
import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  isLoadingExport: false,
  exportFileData: null,
  exportError: null,
};

const slice = createSlice({
  name: 'exportFiles',
  initialState,
  reducers: {
    startLoadingExport(state) {
      state.isLoadingExport = true;
    },
    getExportSuccess(state, action) {
      state.exportFileData = action.payload;
      state.isLoadingExport = false;
    },
    hasExportError(state, action) {
      state.exportError = action.payload;
      state.isLoadingExport = false;
    },
  },
});

export default slice.reducer;

export function exportFile(apiCall) {
  // eslint-disable-next-line consistent-return
  return async () => {
    dispatch(slice.actions.startLoadingExport());
    try {
    const { data } = await apiCall({ responseType: 'blob' });

      dispatch(slice.actions.getExportSuccess(data));
      return data;
    } catch (error) {
      dispatch(slice.actions.hasExportError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}
