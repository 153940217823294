import PropTypes from 'prop-types';

import { Box, Typography, Stack } from '@mui/material';

import { UploadIllustration } from '../../assets';

BlockContent.propTypes = {
  isIllustration: PropTypes.bool,
  isSelected: PropTypes.bool,
};

export default function BlockContent({ isIllustration, isSelected }) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      {isIllustration && <UploadIllustration sx={{ width: 220 }} />}

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          {isSelected ? 'Ձեր ֆայլն արդեն ընտրված է' : 'Կցեք կամ ընտրեք ֆայլ'}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {isSelected ? (
            'Խնդրում ենք սեղմել վերբեռնման կոճակը ֆայլը վերբեռնելու համար'
          ) : (
            <>
              Կցեք ֆայլերն այստեղ &nbsp;
              <Typography variant="body2" component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
                կամ
              </Typography>
              &nbsp;ավելացրեք համակարգչից
            </>
          )}
        </Typography>
      </Box>
    </Stack>
  );
}
