class ChatSocket {
  static _classInstance = null;

  _instance = null;

  _handlers = {};

  isOpenSocket = false;

  constructor() {
    this._instance = new WebSocket(
      `wss://${new URL(process.env.REACT_APP_BASE_URL).host}/ws/chat/?token=${window.localStorage.getItem(
        'accessToken'
      )}`
    );
    this._listeners();
  }

  static getInstance() {
    if (!ChatSocket._classInstance || ChatSocket._classInstance._instance.readyState > 1) {
      ChatSocket._classInstance = new ChatSocket();
    }
    return ChatSocket._classInstance;
  }

  static resetClassInstance() {
    ChatSocket._classInstance = null;
  }

  _listeners() {
    this._instance.onopen = () => {
      this.isOpenSocket = true;
      // @TODO Implement some logic for successful connection
    };
    this._instance.onclose = () => {
      this._handlers = [];
      // @TODO Implement some logic for connection close connection
    };
    this._instance.onerror = () => {
      // @TODO Implement some logic for connection error and show some message for users
      this._instance.close();
    };
    this._onMessage();
  }

  _onMessage() {
    this._instance.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (typeof data !== 'object' || !this._handlers[data.type]) return;
      this._handlers[data.type].forEach(({ callback }) => callback(data));
    };
  }

  send(type, payload) {
    if (this._instance && this._instance.readyState === 0) {
      return;
    }

    if (!this.isOpenSocket || this._instance.readyState > 1) {
      ChatSocket.getInstance();
    }

    const data = { type, ...payload };
    this._instance.send(JSON.stringify(data));
  }

  close() {
    this._instance.close();
  }

  listen(eventName, key, callback) {
    if (!this._handlers[eventName]) {
      this._handlers[eventName] = [];
    }

    const keyExists = this._handlers[eventName].find((item) => item.key === key);
    if (keyExists) return this;

    this._handlers[eventName].push({ callback, key });

    return this;
  }
}

export default ChatSocket;
