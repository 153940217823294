import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import RejectionFiles from './errors/RejectionFiles';
import BlockContent from './BlockContent';
import CsvFilePreview from './CsvFilePreview';

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

UploadCsvFile.propTypes = {
  isIllustration: PropTypes.bool,
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
};

export default function UploadCsvFile({
  isIllustration,
  error = false,
  file,
  onRemove,
  onChange,
  helperText,
  sx,
  ...other
}) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file &&
            !error && {
              pointerEvents: 'none',
            }),
        }}
      >
        <input {...getInputProps({ onChange })} />

        <BlockContent isIllustration={isIllustration} isSelected={!!file} />
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {file && fileRejections.length <= 0 && <CsvFilePreview file={file} onRemove={onRemove} />}

      {helperText && helperText}
    </Box>
  );
}
