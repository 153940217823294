import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import { PATH_DASHBOARD } from 'routes/paths';

import Login from 'containers/pages/auth/Login';
import LoadingScreen from 'components/LoadingScreen';

import { USER_ROLES } from 'helpers/constants/user';

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { user, isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (+user?.role === USER_ROLES.PARTNER && pathname === '/') return <Navigate to={PATH_DASHBOARD.partner.root} />;

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
