export const formatKeys = (data, formatCase = '_') => {
  Object.entries(data).forEach((entry) => {
    const newKey = entry[0].replace(/([a-zA-Z])(?=[A-Z])/g, `$1${formatCase}`).toLowerCase();
    const oldKey = entry[0];

    if (oldKey !== newKey && data[oldKey] && !data[newKey]) {
      Object.defineProperty(data, newKey, Object.getOwnPropertyDescriptor(data, entry[0]));
      delete data[entry[0]];
    }
  });
  return data;
};

export const getChangedFields = (dirtyFields, getValues) =>
  Object.keys(dirtyFields).reduce((sum, item) => ({ ...sum, [item]: getValues(item) }), {});

export const getCustomKeyValuePairObject = (data, key, value) =>
  data.reduce((custom, item) => {
    custom[item[key]] = item[value];
    return custom;
  }, {});
