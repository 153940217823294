import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@mui/material';

import useAuth from 'hooks/useAuth';

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array,
  children: PropTypes.node,
};

const useCurrentRole = () => {
  const { user } = useAuth();

  return +user.role;
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const currentRole = useCurrentRole();
  if (!accessibleRoles.includes(currentRole)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Թույլտվությունը մերժված է</AlertTitle>
          Դուք այս էջ մուտք գործելու թույլտվություն չունեք
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
