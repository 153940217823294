import { Outlet } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import useCollapseDrawer from 'hooks/useCollapseDrawer';

const MainStyle = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3, 0),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(5, 5, 0),
    width: '100%',
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

export default function DashboardLayout() {
  const { collapseClick } = useCollapseDrawer();

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
