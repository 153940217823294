import auth from './api/auth';
import user from './api/user';
import post from './api/post';
import project from './api/project';
import vote from './api/vote';
import property from './api/property';
import issueType from './api/issueType';
import issue from './api/issue';
import chat from './api/chat';
import notification from './api/notification';
import noResident from './api/noResident';
import resident from './api/resident';
import payment from './api/payment';
import callRequest from './api/callRequest';
import saleRealEstate from './api/saleRealEstate';
import partners from './api/partners';
import partnerUsers from './api/partnerUsers';
import partnerDeals from './api/partnerDeals';

const ArcadaApiService = {
  auth,
  user,
  post,
  project,
  vote,
  property,
  issueType,
  issue,
  chat,
  notification,
  noResident,
  resident,
  payment,
  callRequest,
  saleRealEstate,
  partners,
  partnerUsers,
  partnerDeals,
};

export default ArcadaApiService;
