import { getIcon } from 'utils/icon';
import { USER_ROLES } from 'helpers/constants/user';

import { PATH_DASHBOARD } from 'routes/paths';

const ICONS = {
  residents: getIcon('ic_residents'),
  properties: getIcon('ic_properties'),
  applications: getIcon('ic_applications'),
  publications: getIcon('ic_publications'),
  projects: getIcon('ic_projects'),
  votes: getIcon('ic_votes'),
  users: getIcon('ic_users'),
  noResidentUsers: getIcon('ic_no_resident_user'),
  issueTypes: getIcon('ic_issue_types'),
  notifications: getIcon('ic_notifications'),
  callRequests: getIcon('ic_callRequest'),
  saleRealEstate: getIcon('ic_real_estate'),
  payment: getIcon('ic_payments'),
  partners: getIcon('ic_partners'),
};

const navConfig = [
  {
    items: [
      { title: 'Բնակիչներ', path: PATH_DASHBOARD.residents.root, icon: ICONS.residents },
      { title: 'Գույք', path: PATH_DASHBOARD.properties.root, icon: ICONS.properties },
      { title: 'Դիմումներ', path: PATH_DASHBOARD.issues.root, icon: ICONS.applications },
      { title: 'Հրապարակումներ', path: PATH_DASHBOARD.posts.root, icon: ICONS.publications },
      { title: 'Նախագիծ', path: PATH_DASHBOARD.projects.root, icon: ICONS.projects },
      { title: 'Քվեարկություններ', path: PATH_DASHBOARD.votes.root, icon: ICONS.votes },
      { title: 'Օգտագործողներ', path: PATH_DASHBOARD.users.root, icon: ICONS.users },
      { title: 'Օգտատերեր', path: PATH_DASHBOARD.nonResidents.root, icon: ICONS.noResidentUsers },
      { title: 'Դիմումի տեսակներ', path: PATH_DASHBOARD.issueTypes.root, icon: ICONS.issueTypes },
      { title: 'Ծանուցումներ', path: PATH_DASHBOARD.notifications.root, icon: ICONS.notifications },
      { title: 'Զանգի հարցում', path: PATH_DASHBOARD.callRequest.root, icon: ICONS.callRequests },
      { title: 'Վաճառք / Վարձակալություն', path: PATH_DASHBOARD.saleRealEstate.root, icon: ICONS.saleRealEstate },
      { title: 'Վճարումներ', path: PATH_DASHBOARD.payments.root, icon: ICONS.payment },
      { title: 'Գործընկերներ', path: PATH_DASHBOARD.partners.root, icon: ICONS.partners },
    ],
  },
];

const ADMIN_NAV_LIST_EXCLUDE = [];

const SERVICE_COMPANY_NAV_LIST_EXCLUDE = [
  PATH_DASHBOARD.users.root,
  PATH_DASHBOARD.projects.root,
  PATH_DASHBOARD.posts.root,
  PATH_DASHBOARD.issueTypes.root,
  PATH_DASHBOARD.nonResidents.root,
  PATH_DASHBOARD.callRequest.root,
  PATH_DASHBOARD.saleRealEstate.root,
];

const prepareNavConfig = ({ role }) => {
  const navs = [{ items: [] }];

  navs[0].items = [
    ...navConfig[0].items.filter(
      (item) =>
        ![
          ...(+role !== USER_ROLES.SERVICE_COMPANY ? ADMIN_NAV_LIST_EXCLUDE : SERVICE_COMPANY_NAV_LIST_EXCLUDE),
        ].includes(item.path)
    ),
  ];

  return navs;
};

export default prepareNavConfig;
