import useAuth from 'hooks/useAuth';
import createAvatar from 'utils/createAvatar';
import Avatar from './Avatar';

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  return (
    <Avatar
      src={user?.profile_image}
      alt={user?.full_name}
      color={user?.profile_image ? 'default' : createAvatar(user?.full_name).color}
      {...other}
    >
      {createAvatar(user?.full_name).name}
    </Avatar>
  );
}
