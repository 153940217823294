import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import {m, AnimatePresence} from 'framer-motion';

import {List, IconButton, ListItemText, ListItem} from '@mui/material';

import {fData} from '../../utils/formatNumber';
//
import Iconify from '../Iconify';
import {varFade} from '../animate';




CsvFilePreview.propTypes = {
    file: PropTypes.object,
    onRemove: PropTypes.func,
};

export default function CsvFilePreview({file, onRemove}) {
    const hasFile = file.length > 0;

    return (
        <>
            <List disablePadding sx={{...(hasFile && {my: 3})}}>
                <AnimatePresence>
                    <ListItem
                        component={m.div}
                        {...varFade().inRight}
                        sx={{
                            my: 1,
                            px: 2,
                            py: 0.75,
                            borderRadius: 0.75,
                            border: (theme) => `solid 1px ${theme.palette.divider}`,
                        }}
                    >
                        <Iconify icon={'eva:file-fill'} sx={{width: 28, height: 28, color: 'text.secondary', mr: 2}}/>

                        <ListItemText
                            primary={isString(file) ? file : file.name}
                            secondary={isString(file) ? '' : fData(file.size || 0)}
                            primaryTypographyProps={{variant: 'subtitle2'}}
                            secondaryTypographyProps={{variant: 'caption'}}
                        />

                        <IconButton edge="end" size="small" onClick={onRemove}>
                            <Iconify icon={'eva:close-fill'}/>
                        </IconButton>
                    </ListItem>
                    );
                    })}
                </AnimatePresence>
            </List>
        </>
    );
}
