import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class PartnerDeals extends ApiService {
  getPartnerDeals(params) {
    return this.get(API_ENDPOINTS.partnerDeals.root, params);
  }

  getPartnerDeal(id) {
    return this.get(`${API_ENDPOINTS.partnerDeals.root}${id}/`);
  }

  addPartnerDeal(data) {
    return this.post(API_ENDPOINTS.partnerDeals.root, data);
  }

  editPartnerDeal(data, id) {
    return this.patch(`${API_ENDPOINTS.partnerDeals.root}${id}/`, data);
  }

  deletePartnerDeal(id) {
    return this.delete(`${API_ENDPOINTS.partnerDeals.root}${id}/`);
  }
}

export default new PartnerDeals();
