import { useEffect } from 'react';
import { useSelector, useDispatch } from 'redux/store';
import { useSnackbar } from 'notistack';
import { resetNotifications } from 'redux/slices/globalNotifications';

export default function GlobalNotification() {
  const { notifications } = useSelector((state) => state.globalNotifications);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
  if (notifications && notifications.length) {
    notifications.forEach((notification) => enqueueSnackbar(notification.message, { variant: notification.type || 'error' }));
      dispatch(resetNotifications());
    }
  }, [notifications, dispatch])

  return null;
}
