import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from 'notistack';

import { PATH_AUTH } from 'routes/paths';

import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';

import Iconify from 'components/Iconify';
import { FormProvider, RHFTextField } from 'components/hook-form';
import { EMAIL_REG_EXP } from 'utils/validations';

export default function LoginForm() {
  const { login } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const loginValidations = Yup.object().shape({
    email: Yup.string().required('Պարտադիր դաշտ').matches(EMAIL_REG_EXP, 'Մուտքագրեք վավեր էլ․ հասցե'),
    password: Yup.string()
      .required('Պարտադիր դաշտ')
      .test('password', 'Այս դաշտը չի կարող դատարկ լինել', (value) => value.trim()),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(loginValidations),
    defaultValues,
    mode: 'onTouched',
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      reset();
      if (isMountedRef.current) {
        Object.entries(error).forEach((entry) => {
          if (Object.keys(defaultValues).includes(entry[0])) setError(entry[0], { type: 'manual', message: entry[1] });
          else {
            setError('afterSubmit', { type: 'manual', message: entry[1] || 'Ինչ որ բան սխալ է' });
            enqueueSnackbar(`${entry[1] || 'Ինչ որ բան սխալ է'}`, { variant: 'error' });
          }
        });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="Էլ․ հասցե" required />

        <RHFTextField
          name="password"
          label="Գաղտնաբառ"
          required
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Մոռացե՞լ եք գաղտնաբառը
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Մուտք
      </LoadingButton>
    </FormProvider>
  );
}
