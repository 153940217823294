import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
// utils
import cssStyles from 'utils/cssStyles';
//
import Iconify from 'components/Iconify';

DownloadButton.propTypes = {
  onDownload: PropTypes.func,
};

export default function DownloadButton({ onDownload }) {
  const theme = useTheme();

  return (
    <IconButton
      onClick={onDownload}
      sx={{
        p: 0,
        top: 0,
        right: 0,
        width: 1,
        height: 1,
        zIndex: 9,
        opacity: 0,
        position: 'absolute',
        borderRadius: 'unset',
        justifyContent: 'center',
        bgcolor: 'grey.800',
        color: 'common.white',
        transition: theme.transitions.create('opacity'),

        '&:hover': {
          borderRadius: 1.25,
          overflow: 'hidden',
          border: (theme) => `solid 1px ${theme.palette.divider}`,
          opacity: 1,
          ...cssStyles(theme).bgBlur({
            opacity: 0.64,
            color: theme.palette.grey[900],
          }),
        },
      }}
    >
      <Iconify icon="eva:arrow-circle-down-fill" width={24} />
    </IconButton>
  );
}
