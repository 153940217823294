import {API_ENDPOINTS} from 'config';
import {ApiService} from '../apiService';

class IssueType extends ApiService {
    getIssueTypes() {
        return this.get(API_ENDPOINTS.issueType.root);
    }

    getIssueType(id) {
        return this.get(`${API_ENDPOINTS.issueType.root}${id}/`);
    }

    addIssueType(data) {
        return this.post(API_ENDPOINTS.issueType.root, data);
    }

    editIssueType(data, id) {
        return this.patch(`${API_ENDPOINTS.issueType.root}${id}/`, data);
    }

    deleteIssueType(id) {
        return this.delete(`${API_ENDPOINTS.issueType.root}${id}/`);
    }
}

export default (new IssueType())