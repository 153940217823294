import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class Chat extends ApiService {
  getRoom(id) {
    return this.get(`${API_ENDPOINTS.chat.room()}${id}`);
  }

  messageFile(data) {
    return this.post(`${API_ENDPOINTS.chat.messageFile()}`, data);
  }
}

export default new Chat();
