import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Button, Stack, Tooltip } from '@mui/material';
// components
import Iconify from 'components/Iconify';
//
import RejectionFiles from './errors/RejectionFiles';
import MultiFilePreview from './preview/MultiFilePreview';
import SingleFilePreview from './preview/SingleFilePreview';

const StyledDropZone = styled('div')(({ theme }) => ({
  width: 60,
  height: 64,
  fontSize: 24,
  display: 'flex',
  flexShrink: 0,
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0.5),
  color: theme.palette.text.disabled,
  borderRadius: theme.shape.borderRadius,
  border: `dashed 1px ${theme.palette.divider}`,
  backgroundColor: alpha(theme.palette.grey[500], 0.08),
  '&:hover': {
    opacity: 0.72,
  },
}));

UploadBox.propTypes = {
  sx: PropTypes.object,
  files: PropTypes.oneOfType([PropTypes.array, PropTypes.object, null]),
  showPreview: PropTypes.bool,
  multiple: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.node,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.node,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onDownload: PropTypes.func,
};

export default function UploadBox({
  files,
  placeholder,
  showPreview,
  multiple,
  error,
  helperText,
  errorMessage,
  disabled,
  onRemove,
  onRemoveAll,
  onDownload,
  sx,
  ...other
}) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    disabled,
    ...other,
  });

  const hasFiles = (files && files.length > 0) || (files && Object.keys(files).length > 0);

  const isError = isDragReject || error;

  if (multiple) {
    return (
      <Stack flexDirection="row" flexWrap="wrap">
        <StyledDropZone
          {...getRootProps()}
          sx={{
            ...(isDragActive && {
              opacity: 0.72,
            }),
            ...(isError && {
              color: 'error.main',
              bgcolor: 'error.lighter',
              borderColor: 'error.light',
            }),
            ...(disabled && {
              opacity: 0.48,
              pointerEvents: 'none',
            }),
            ...sx,
          }}
        >
          <input {...getInputProps()} />

          {placeholder || <Iconify icon="eva:cloud-upload-fill" width={28} />}
        </StyledDropZone>

        {hasFiles && (
          <>
            <MultiFilePreview
              showPreview={showPreview}
              files={files}
              onRemove={onRemove}
              onDownload={onDownload}
              thumbnail
              sx={{ width: 60, height: 64 }}
            />

            {onRemoveAll && (
              <Tooltip title="Հեռացնել բոլորը">
                <Button
                  color="inherit"
                  variant="outlined"
                  size="small"
                  onClick={onRemoveAll}
                  sx={{
                    m: 0.5,
                    width: 60,
                    minWidth: 60,
                    height: 64,
                    borderRadius: 1.25,
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  <Iconify icon="eva:close-fill" width={32} />
                </Button>
              </Tooltip>
            )}
          </>
        )}
        {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} errorMessage={errorMessage} />}
        {helperText && helperText}
      </Stack>
    );
  }

  return (
    <Stack flexDirection="row" flexWrap="wrap">
      {!hasFiles && (
        <StyledDropZone
          {...getRootProps()}
          sx={{
            ...(isDragActive && {
              opacity: 0.72,
            }),
            ...(isError && {
              color: 'error.main',
              bgcolor: 'error.lighter',
              borderColor: 'error.light',
            }),
            ...(disabled && {
              opacity: 0.48,
              pointerEvents: 'none',
            }),
            ...sx,
          }}
        >
          <input {...getInputProps()} />

          {placeholder || <Iconify icon="eva:cloud-upload-fill" width={28} />}
        </StyledDropZone>
      )}

      {hasFiles && (
        <SingleFilePreview
          file={files}
          showPreview={showPreview}
          onRemove={onRemove}
          onDownload={onDownload}
          thumbnail
          sx={{ width: 60, height: 64 }}
        />
      )}
    </Stack>
  );
}
