import PropTypes from 'prop-types';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, IconButton, Stack, Typography } from '@mui/material';
// utils
import { fData } from 'utils/formatNumber';
//
import Iconify from 'components/Iconify';
import { varFade } from 'components/animate';
import FileThumbnail, { fileData } from 'components/file-thumbnail';

MultiFilePreview.propTypes = {
  sx: PropTypes.object,
  files: PropTypes.oneOfType([PropTypes.array, PropTypes.object, null]),
  showPreview: PropTypes.bool,
  onRemove: PropTypes.func,
  onDownload: PropTypes.func,
  imageView: PropTypes.bool,
};

export default function MultiFilePreview({ showPreview = true, files, imageView = false, onRemove, onDownload, sx }) {
  if (!files?.length) {
    return null;
  }

  return (
    <AnimatePresence initial={false}>
      {files.map((file) => {
        const { key, name = '', size = 0 } = fileData(file);

        const isNotFormatFile = typeof file === 'string';

        if (showPreview) {
          return (
            <Box
              key={key}
              component={m.div}
              {...varFade().inUp}
              sx={{
                m: 0.5,
                mb: 2,
                width: 80,
                height: 80,
                display: 'inline-flex',
                position: 'relative',
                ...sx,
              }}
            >
              <Stack
                alignItems="center"
                display="inline-flex"
                justifyContent="center"
                sx={{
                  width: 1,
                  height: 1,
                  overflow: 'hidden',
                  position: 'relative',
                  borderRadius: 1.25,
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                }}
              >
                <FileThumbnail
                  tooltip
                  imageView={imageView}
                  file={file}
                  sx={{ position: 'absolute' }}
                  imgSx={{ position: 'absolute' }}
                  onDownload={file?.id ? onDownload : null}
                />
              </Stack>
              {onRemove && (
                <IconButton
                  size="small"
                  onClick={() => onRemove(file)}
                  sx={{
                    top: -10,
                    right: -5,
                    p: '1px',
                    position: 'absolute',
                    color: (theme) => alpha(theme.palette.common.white, 0.72),
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                    '&:hover': {
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                    },
                  }}
                >
                  <Iconify icon="eva:close-fill" width={16} />
                </IconButton>
              )}
            </Box>
          );
        }

        return (
          <Stack
            key={key}
            component={m.div}
            {...varFade().inUp}
            spacing={2}
            direction="row"
            alignItems="center"
            sx={{
              my: 1,
              px: 1,
              py: 0.75,
              borderRadius: 0.75,
              border: (theme) => `solid 1px ${theme.palette.divider}`,
              ...sx,
            }}
          >
            <FileThumbnail file={file} />

            <Stack flexGrow={1} sx={{ minWidth: 0 }}>
              <Typography variant="subtitle2" noWrap>
                {isNotFormatFile ? file : name}
              </Typography>

              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {isNotFormatFile ? '' : fData(size)}
              </Typography>
            </Stack>

            {onRemove && (
              <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                <Iconify icon="eva:close-fill" />
              </IconButton>
            )}
          </Stack>
        );
      })}
    </AnimatePresence>
  );
}
