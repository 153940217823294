import { createSlice } from '@reduxjs/toolkit';

import ArcadaApiService from 'helpers/service/arcadaApiService';
import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { getFormData } from 'utils/formData';
import { TABLE_ROWS_COUNT_OPTIONS_MAX } from 'utils/table';

import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  // list
  residents: null,
  isLoadingResidents: [],
  errorResidents: null,
  owners: null,
  errorOwners: null,
  // resident
  resident: null,
  isLoadingResident: false,
  errorResident: null,
  // family members
  isLoadingResidentFamilyMember: false,
  errorResidentFamilyMember: null,
  // real properties
  isLoadingRealProperties: false,
  errorRealProperties: null,
  // csv modal
  csvUploadModalLoading: false,
  errorResidentCsv: null,
  //
  isCreateResident: false,
};

const slice = createSlice({
  name: 'resident',
  initialState,
  reducers: {
    // residents
    startLoadingResidents(state) {
      state.isLoadingResidents.push(true);
    },
    hasSuccessResidents(state, action) {
      state.residents = action.payload;
      state.isLoadingResidents.pop();
    },
    hasErrorResidents(state, action) {
      state.isLoadingResidents.pop();
      state.errorResidents = action.payload;
    },
    // owners
    hasSuccessOwners(state, action) {
      state.owners = action.payload;
    },
    hasErrorOwners(state, action) {
      state.errorOwners = action.payload;
    },
    // resident
    startLoadingResident(state) {
      state.isLoadingResident = true;
    },
    hasSuccessResident(state, action) {
      state.isLoadingResident = false;
      state.errorResident = false;
      state.resident = action.payload;
    },
    hasErrorResident(state, action) {
      state.isLoadingResident = false;
      state.errorResident = action.payload;
    },
    resetResident(state) {
      state.resident = null;
      state.errorResident = null;
    },
    // family members
    startLoadingResidentFamilyMember(state) {
      state.isLoadingResidentFamilyMember = true;
    },
    hasSuccessResidentFamilyMember(state) {
      state.isLoadingResidentFamilyMember = false;
      state.errorResidentFamilyMember = false;
    },
    hasErrorResidentFamilyMember(state, action) {
      state.isLoadingResidentFamilyMember = false;
      state.errorResidentFamilyMember = action.payload;
    },
    // real properties
    startLoadingRealProperties(state) {
      state.isLoadingRealProperties = true;
    },
    hasSuccessRealProperties(state) {
      state.isLoadingRealProperties = false;
      state.errorRealProperties = false;
    },
    hasErrorRealProperties(state, action) {
      state.isLoadingRealProperties = false;
      state.errorRealProperties = action.payload;
    },
    // csv modal
    startCsvUploadModalLoading(state) {
      state.csvUploadModalLoading = true;
    },
    hasSuccessResidentCsv(state) {
      state.csvUploadModalLoading = false;
      state.error = false;
    },
    hasErrorResidentCsv(state, action) {
      state.csvUploadModalLoading = false;
      state.errorResidentCsv = action.payload;
    },
    //
    setIsCreateResident(state, action) {
      state.isCreateResident = action.payload;
    },
  },
});

export default slice.reducer;

export function getResidents(
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering = 'user__first_name',
  order = 1,
  search,
  filters
) {
  return async () => {
    dispatch(slice.actions.startLoadingResidents());
    try {
      if (search) search = search.trim();
      const response = await ArcadaApiService.resident.getResidents({
        skip,
        take,
        ordering,
        ordering_desc: order,
        search,
        ...filters,
      });
      dispatch(slice.actions.hasSuccessResidents(response.data));
    } catch (error) {
      dispatch(slice.actions.hasErrorResidents(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getResident(id) {
  return async () => {
    dispatch(slice.actions.startLoadingResident());
    try {
      const { data } = await ArcadaApiService.resident.getResident(id);
      dispatch(slice.actions.hasSuccessResident(data));
    } catch (error) {
      dispatch(slice.actions.hasErrorResident(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function addFamilyMember(data) {
  return async () => {
    // dispatch(slice.actions.startLoadingResidentFamilyMember());
    const formData = getFormData(data);

    try {
      await ArcadaApiService.resident.addFamilyMember(formData);
      // dispatch(slice.actions.hasSuccessResidentFamilyMember());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Ընտանիքի անդամն ավելացված է'])));
      return false;
    } catch (error) {
      // dispatch(slice.actions.hasErrorResidentFamilyMember(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
      return error;
    }
  };
}

export function addResident(data) {
  return async () => {
    dispatch(slice.actions.startLoadingResident());
    const formData = getFormData(data);

    try {
      const { data } = await ArcadaApiService.resident.addResident(formData);
      dispatch(slice.actions.hasSuccessResident(data));
      dispatch(slice.actions.setIsCreateResident(true));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Բնակիչն ավելացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasErrorResident(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function addResidentCsv(data) {
  return async () => {
    dispatch(slice.actions.startCsvUploadModalLoading());
    const formData = getFormData(data);

    try {
      const {
        data: { invalid_residents: invalidResidents },
      } = await ArcadaApiService.resident.addResidentCsv(formData);

      if (invalidResidents.length) {
        const errorMessage = (
          <div>
            Սխալ տվյալների պատճառով հետևյալ բնակիչները չեն ավելացվել՝
            <div style={{ wordBreak: 'break-word' }}>{Object.values(invalidResidents).join(', ')}</div>
          </div>
        );

        dispatch(slice.actions.hasErrorResidentCsv(errorMessage));
        return;
      }
      dispatch(slice.actions.hasSuccessResidentCsv());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Բնակիչներն ավելացված են'])));
      dispatch(getResidents());
    } catch (error) {
      dispatch(slice.actions.hasErrorResidentCsv(error));
      dispatch(
        globalNotificationSlice.actions.hasNotification(FormatErrorResponse({ message: ['Ինչ որ բան սխալ է'] }))
      );
    }
  };
}

export function editResident(payload, id) {
  return async () => {
    dispatch(slice.actions.startLoadingResident());

    try {
      const formData = getFormData(payload);
      const { data } = await ArcadaApiService.resident.editResident(formData, id);

      dispatch(slice.actions.hasSuccessResident(data));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Բնակիչը թարմացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasErrorResident(error));
      dispatch(
        globalNotificationSlice.actions.hasNotification(FormatErrorResponse({ message: ['Ինչ որ բան սխալ է'] }))
      );
    }
  };
}

export function toggleResidentStatus(
  id,
  isActive,
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering = 'user__first_name',
  order = 0,
  searching,
  filters
) {
  const formData = getFormData({ is_active: isActive });

  return async () => {
    dispatch(slice.actions.startLoadingResidents());
    try {
      await ArcadaApiService.user.toggleUserStatus(formData, id);
      dispatch(getResidents(skip, take, ordering, order, searching, filters));
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function addRealProperties(data) {
  return async () => {
    dispatch(slice.actions.startLoadingRealProperties());
    try {
      await ArcadaApiService.resident.addRealProperties(data);
      dispatch(slice.actions.hasSuccessRealProperties());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Բնակչի գույքը ավելացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasErrorRealProperties(error));
      dispatch(
        globalNotificationSlice.actions.hasNotification(FormatErrorResponse({ message: ['Ինչ որ բան սխալ է'] }))
      );
    }
  };
}

export function editRealProperties({ updateRealProperties, newRealProperties, deleteRealProperties }, id) {
  return async () => {
    dispatch(slice.actions.startLoadingRealProperties());
    try {
      if (newRealProperties?.length)
        await ArcadaApiService.resident.addRealProperties({
          real_properties: newRealProperties,
          resident: id,
        });

      if (updateRealProperties?.length) {
        await ArcadaApiService.resident.editRealProperties({
          real_properties: updateRealProperties,
          resident: id,
        });
      }

      if (deleteRealProperties?.length)
        await ArcadaApiService.resident.deleteRealProperties({ real_properties: deleteRealProperties }, id);

      dispatch(slice.actions.hasSuccessRealProperties());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Բնակչի գույքը թարմացված է'])));
      return false;
    } catch (error) {
      dispatch(slice.actions.hasErrorRealProperties(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
      return error;
    }
  };
}

export function deleteRealProperties(data, id) {
  return async () => {
    dispatch(slice.actions.startLoadingRealProperties());
    try {
      await ArcadaApiService.resident.deleteRealProperties(data, id);
      dispatch(slice.actions.hasSuccessRealProperties());
    } catch (error) {
      dispatch(slice.actions.hasErrorRealProperties(error));
      dispatch(
        globalNotificationSlice.actions.hasNotification(FormatErrorResponse({ message: ['Ինչ որ բան սխալ է'] }))
      );
    }
  };
}

export function resetResident() {
  return () => {
    dispatch(slice.actions.resetResident());
    dispatch(slice.actions.setIsCreateResident(false));
  };
}

export function getOwners(
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering = 'user__first_name',
  order = 1,
  search,
  filters
) {
  return async () => {
    try {
      const response = await ArcadaApiService.resident.getResidents({
        skip,
        take,
        ordering,
        ordering_desc: order,
        search,
        ...filters,
      });
      dispatch(slice.actions.hasSuccessOwners(response.data));
    } catch (error) {
      dispatch(slice.actions.hasErrorOwners(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}
