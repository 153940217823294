import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class Issue extends ApiService {
  editIssue(data, id) {
    return this.patch(`${API_ENDPOINTS.issue.root}${id}/`, data);
  }

  monthlyData(data) {
    return this.get(`${API_ENDPOINTS.issue.monthlyData}`, data);
  }
}

export default new Issue();
