import { formatKeys } from './formatObject';

export const getFormData = (data) => {
  const formData = new FormData();
  if (!data) return formData;

  const newData = formatKeys({ ...data });

  Object.entries(newData).forEach((entry) => formData.append(entry[0], entry[1]));

  return formData;
};
