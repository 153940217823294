import axios from 'utils/axios';

const buildQuery = (query) => {
  const newQuery = Object.keys(query)
    .filter((key) => query[key])
    .map((key) => `${key}=${query[key]}`)
    .join('&');

  if (newQuery) return `?${newQuery}`;
  return '';
};

const waitApiCallTime = 300000;

export class ApiService {
  constructor() {
    if (!process.env.REACT_APP_BASE_URL) throw new Error('Please check REACT_APP_BASE_URL config');
    this.BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`;
  }

  buildUrl(resource) {
    return `${this.BASE_URL}/${resource}`;
  }

  async get(resource, query = {}, config) {
    return axios.get(`${this.buildUrl(resource) + buildQuery(query)}`, {
      ...config,
      timeout: waitApiCallTime,
    });
  }

  async post(resource, body, config) {
    return axios.post(this.buildUrl(resource), body, {
      ...config,
      timeout: waitApiCallTime,
    });
  }

  async patch(resource, body, query = {}, config) {
    return axios.patch(`${this.buildUrl(resource) + buildQuery(query)}`, body, {
      ...config,
      timeout: waitApiCallTime,
    });
  }

  async put(resource, body, query = {}, config) {
    return axios.put(`${this.buildUrl(resource) + buildQuery(query)}`, body, {
      ...config,
      timeout: waitApiCallTime,
    });
  }

  async delete(resource, body, query = {}, config) {
    return axios.delete(`${this.buildUrl(resource) + buildQuery(query)}`, {
      ...config,
      timeout: waitApiCallTime,
      data: body,
    });
  }
}
