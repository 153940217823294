import { createSlice } from '@reduxjs/toolkit';
import ArcadaApiService from 'helpers/service/arcadaApiService';

import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { getFormData } from 'utils/formData';

import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  isLoading: false,
  isProjectStructureLoading: false,
  isProjectApartmentsLoading: false,
  projects: null,
  project: null,
  projectStructure: null,
  projectApartments: null,
  error: null,
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getProjectStructureStartLoading(state) {
      state.isProjectStructureLoading = true;
    },
    getProjectApartmentsLoading(state) {
      state.isProjectApartmentsLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.isProjectStructureLoading = false;
      state.error = action.payload;
    },
    getEntitiesSuccess(state, action) {
      state.isLoading = false;
      state.projects = [...action.payload];
    },
    getEntitySuccess(state, action) {
      state.isLoading = false;
      state.project = action.payload;
    },
    getProjectStructureSuccess(state, action) {
      state.isProjectStructureLoading = false;
      state.projectStructure = [...action.payload];
    },
    getProjectApartmentsSuccess(state, action) {
      state.isProjectApartmentsLoading = false;
      state.projectApartments = [...action.payload];
    },
    hasSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    resetEntity(state) {
      state.project = null;
      state.error = null;
    },
  },
});

export default slice.reducer;

export function getProjects() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ArcadaApiService.project.getProjects();
      dispatch(slice.actions.getEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getProject(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ArcadaApiService.project.getProject(id);
      dispatch(slice.actions.getEntitySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getProjectStructure() {
  return async () => {
    dispatch(slice.actions.getProjectStructureStartLoading());
    try {
      const { data } = await ArcadaApiService.project.getProjectStructure();
      dispatch(slice.actions.getProjectStructureSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function addProject(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const formData = getFormData(data);

    try {
      await ArcadaApiService.project.addProject(formData);
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Նախագիծն ավելացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function editProject(data, id) {
  return async () => {
    const formData = getFormData(data);
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.project.editProject(formData, id);
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Նախագիծը թարմացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getProjectApartments(filter) {
  return async () => {
    dispatch(slice.actions.getProjectApartmentsLoading());
    try {
      const { data } = await ArcadaApiService.project.getProjectApartments(filter);
      dispatch(slice.actions.getProjectApartmentsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function resetProject() {
  return () => {
    dispatch(slice.actions.resetEntity());
  };
}
