export const FormatErrorResponse = (errors) => {
  const errorMessages = [];

  if (errors instanceof Object) {
    Object.values(errors).map((message) => errorMessages.push({ message, type: 'error' }));
    return errorMessages;
  }

  Object.values(errors).forEach((error) =>
    error.forEach((item) => {
      if (item instanceof Object) Object.values(item).map((message) => errorMessages.push({ message, type: 'error' }));
      else errorMessages.push({ message: error, type: 'error' });
    })
  );

  return errorMessages;
};

export const FormatSuccessResponse = (success) => success.map((item) => ({ message: item, type: 'success' }));
