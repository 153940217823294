import PropTypes from 'prop-types';
import { hy } from 'date-fns/locale';
// @mui
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
// hooks
import { Controller, useFormContext } from 'react-hook-form';

RHFDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  minDate: PropTypes.any,
  disablePast: PropTypes.bool,
  disabled: PropTypes.bool,
  OpenPickerButtonProps: PropTypes.object,
};

export default function RHFDatePicker({
  name,
  label,
  disablePast,
  disabled,
  OpenPickerButtonProps,
  minDate,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider adapterLocale={hy} dateAdapter={AdapterDateFns}>
          <DatePicker
            {...field}
            label={label}
            mask="____-__-__"
            inputFormat="yyyy-MM-dd"
            views={['year', 'month', 'day']}
            minDate={minDate}
            disabled={disabled}
            disablePast={disablePast}
            OpenPickerButtonProps={OpenPickerButtonProps}
            renderInput={(params) => (
              <TextField {...params} name={name} error={!!error} helperText={error?.message} {...other} />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
}
