import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class CallRequest extends ApiService {
  getCallRequest(params) {
    return this.get(API_ENDPOINTS.callRequest.root, params);
  }

  getCallRequestOpenCount() {
    return this.get(API_ENDPOINTS.callRequest.count);
  }

  editCallRequest(data, id) {
    return this.put(`${API_ENDPOINTS.callRequest.root}${id}/`, data);
  }

  deleteCallRequest(id) {
    return this.delete(`${API_ENDPOINTS.callRequest.root}${id}/`);
  }
}

export default new CallRequest();
