import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Card, Typography, useTheme } from '@mui/material';

import useResponsive from 'hooks/useResponsive';

import Page from 'components/Page';
import Logo from 'components/Logo';
import Image from 'components/Image';

import { LoginForm } from 'containers/sections/auth/login';

import { LoginBanner } from 'assets';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(() => ({
  width: '100%',
  height: '100vh',
  maxWidth: 500,
  display: 'flex',
  justifyContent: 'center',
  borderRadius: 'unset',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const mdUp = useResponsive('up', 'md');
  const theme = useTheme();

  return (
    <Page title="Մուտք">
      <RootStyle>
        <HeaderStyle>
          <Logo sx={{ ...(mdUp && { color: theme.palette.common.white }) }} />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Image alt="login" src={LoginBanner} sx={{ width: '100%' }} />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Մուտք գործել Արկադա
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Մուտքագրել տվյալները</Typography>
              </Box>
            </Stack>
            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
