/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';

import { FormProvider as Form } from 'react-hook-form';

FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
  methods: PropTypes.object.isRequired,
  sx: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default function FormProvider({ children, onSubmit, methods, sx }) {
  const checkKeyDown = (event) => {
    if (event.code === 'Enter') event.preventDefault();
  };

  return (
    <Form {...methods}>
      <form onSubmit={onSubmit} onKeyDown={(event) => checkKeyDown(event)} style={{ ...sx }}>
        {children}
      </form>
    </Form>
  );
}
