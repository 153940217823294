import { createSlice } from '@reduxjs/toolkit';

import ArcadaApiService from 'helpers/service/arcadaApiService';
import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { getFormData } from 'utils/formData';
import { TABLE_ROWS_COUNT_OPTIONS_MAX } from 'utils/table';

import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  isLoadingPartnerUsers: false,
  isLoadingPartnerUser: false,
  partnerUsers: null,
  partnerUser: null,
  error: null,
};

const slice = createSlice({
  name: 'partnerUsers',
  initialState,
  reducers: {
    startLoadingPartnerUsers(state) {
      state.isLoadingPartnerUsers = true;
    },
    startLoadingPartnerUser(state) {
      state.isLoadingPartnerUser = true;
    },
    getEntitiesSuccess(state, action) {
      state.isLoadingPartnerUsers = false;
      state.partnerUsers = action.payload;
    },
    getEntitySuccess(state, action) {
      state.isLoadingPartnerUser = false;
      state.partnerUser = action.payload;
    },
    hasError(state, action) {
      state.isLoadingPartnerUsers = false;
      state.isLoadingPartnerUser = false;
      state.error = action.payload;
    },
    hasSuccess(state) {
      state.isLoadingPartnerUsers = false;
      state.isLoadingPartnerUser = false;
      state.error = null;
    },
    resetEntity(state) {
      state.partnerUser = null;
      state.error = null;
    },
  },
});

export default slice.reducer;

export function getPartnerUsers(
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering = '-email',
  search,
  filters
) {
  return async () => {
    dispatch(slice.actions.startLoadingPartnerUsers());
    try {
      const response = await ArcadaApiService.partnerUsers.getPartnerUsers({
        skip,
        take,
        ordering,
        search,
        ...filters,
      });
      dispatch(slice.actions.getEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getPartnerUser(id) {
  return async () => {
    dispatch(slice.actions.startLoadingPartnerUser());
    try {
      const response = await ArcadaApiService.partnerUsers.getPartnerUser(id);
      dispatch(slice.actions.getEntitySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function addPartnerUser(skip, take, data) {
  return async () => {
    // dispatch(slice.actions.startLoadingPartnerUser());
    const formData = getFormData(data);

    try {
      await ArcadaApiService.partnerUsers.addPartnerUser(formData);
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Օգտագործողն ավելացված է'])));
      dispatch(getPartnerUsers(skip, take, null, null, { partner: data.partner }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function editPartnerUser(skip, take, data, id) {
  return async () => {
    // dispatch(slice.actions.startLoadingPartnerUser());
    const formData = getFormData(data);
    try {
      await ArcadaApiService.partnerUsers.editPartnerUser(formData, id);
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Օգտագործողը թարմացված է'])));
      dispatch(getPartnerUsers(skip, take, null, null, { partner: data.partner }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function resetPartnerUser() {
  return () => {
    dispatch(slice.actions.resetEntity());
  };
}
