import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class Property extends ApiService {
  getProperties(params) {
    return this.get(API_ENDPOINTS.property.root, params);
  }

  getListOfProperties(params) {
    return this.get(API_ENDPOINTS.property.listOfProperties(), params);
  }

  getListOfAvailableProperties(params) {
    return this.get(API_ENDPOINTS.property.listOfAvailableProperties(), params);
  }

  getProperty(id) {
    return this.get(`${API_ENDPOINTS.property.root}${id}/`);
  }

  addProperty(data) {
    return this.post(API_ENDPOINTS.property.root, data);
  }

  editProperty(data, id) {
    return this.put(`${API_ENDPOINTS.property.root}${id}/`, data);
  }

  deleteProperty(id) {
    return this.delete(`${API_ENDPOINTS.property.root}${id}/`);
  }
}

export default new Property();
