import { createSlice } from '@reduxjs/toolkit';
import { ISSUE_STATUS } from 'helpers/constants/issue';
import ArcadaApiService from 'helpers/service/arcadaApiService';
import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  issueMonthlyData: [],
  isLoadingIssueMonthlyData: false,
  isLoading: false,
  error: null,
  errorIssueMonthlyData: null,
  issueStatus: ISSUE_STATUS.OPEN,
  issueProject: null,
};

const slice = createSlice({
  name: 'issue',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadingIssueMonthlyData(state) {
      state.isLoadingIssueMonthlyData = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasErrorIssueMonthlyData(state, action) {
      state.isLoadingIssueMonthlyData = false;
      state.errorIssueMonthlyData = action.payload;
    },
    hasSuccessIssueMonthlyData(state, action) {
      state.isLoadingIssueMonthlyData = false;
      state.errorIssueMonthlyData = null;
      state.issueMonthlyData = action.payload;
    },
    setIssueStatus(state, action) {
      state.issueStatus = action.payload;
    },
    setIssueProject(state, action) {
      state.issueProject = action.payload;
    },
  },
});

export default slice.reducer;

export function getIssueMonthlyData(project) {
  return async () => {
    dispatch(slice.actions.startLoadingIssueMonthlyData());

    try {
      const response = await ArcadaApiService.issue.monthlyData({
        project,
      });

      dispatch(slice.actions.hasSuccessIssueMonthlyData(response.data));
    } catch (error) {
      dispatch(slice.actions.hasErrorIssueMonthlyData(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function editIssue(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      await ArcadaApiService.issue.editIssue(data, id);
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Դիմումը թարմացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export const { setIssueStatus, setIssueProject } = slice.actions;
