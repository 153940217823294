import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class PartnerUsers extends ApiService {
  getPartnerUsers(params) {
    return this.get(API_ENDPOINTS.partnerUsers.root, params);
  }

  getPartnerUser(id) {
    return this.get(`${API_ENDPOINTS.partnerUsers.root}${id}/`);
  }

  getPartnerUserData() {
    return this.get(`${API_ENDPOINTS.partnerUsers.root}data/`);
  }

  addPartnerUser(data) {
    return this.post(API_ENDPOINTS.partnerUsers.root, data);
  }

  editPartnerUser(data, id) {
    return this.patch(`${API_ENDPOINTS.partnerUsers.root}${id}/`, data);
  }

  deletePartnerUser(id) {
    return this.delete(`${API_ENDPOINTS.partnerUsers.root}${id}/`);
  }
}

export default new PartnerUsers();
