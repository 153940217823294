import {API_ENDPOINTS} from 'config';
import {ApiService} from '../apiService';

class Auth extends ApiService {
    login(email, password) {
        return this.post(API_ENDPOINTS.auth.login(), {
            email,
            password,
        });
    }

    logout() {
        return this.post(API_ENDPOINTS.auth.logout());
    }
}

export default (new Auth())