import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class Vote extends ApiService {
  getVotes(params) {
    return this.get(API_ENDPOINTS.vote.root, params);
  }

  getVoteQuestions(id) {
    return this.get(API_ENDPOINTS.vote.voteQuestions(id));
  }

  getVoteResults(id, params, config) {
    return this.get(API_ENDPOINTS.vote.voteResults(id), params, config);
  }

  getVoteResultsExport(id, params, config) {
    return this.get(API_ENDPOINTS.vote.voteResultsExport(id), params, config);
  }

  getVoteParticipatedUsers(id, params, config) {
    return this.get(API_ENDPOINTS.vote.voteParticipatedUsers(id), params, config);
  }

  getVoteQuestionUserAnswers(id) {
    return this.get(API_ENDPOINTS.vote.voteQuestionUserAnswers(id));
  }

  addVote(data) {
    return this.post(API_ENDPOINTS.vote.root, data);
  }

  addVoteQuestions(id, data) {
    return this.post(API_ENDPOINTS.vote.voteQuestions(id), data);
  }

  addVoteQuestionAttachments(data) {
    return this.post(API_ENDPOINTS.vote.voteQuestionAttachments(), data);
  }

  editVote(id, data) {
    return this.patch(`${API_ENDPOINTS.vote.root}${id}/`, data);
  }

  sendVote(id, data) {
    return this.post(API_ENDPOINTS.vote.sendVote(id), data);
  }

  deleteVote(id) {
    return this.delete(`${API_ENDPOINTS.vote.root}${id}/`);
  }

  deleteBulkVoteQuestionAttachments(data) {
    return this.post(API_ENDPOINTS.vote.deleteBulkVoteQuestionAttachments(), data);
  }
}

export default new Vote();
