import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class SaleRealEstate extends ApiService {
  getSaleRealEstates(params) {
    return this.get(API_ENDPOINTS.saleRealEstate.root, params);
  }

  getSaleRealEstate(id) {
    return this.get(`${API_ENDPOINTS.saleRealEstate.root}${id}/`);
  }

  addSaleRealEstate(params) {
    return this.post(API_ENDPOINTS.saleRealEstate.root, params);
  }

  editSaleRealEstate(params, id) {
    return this.put(`${API_ENDPOINTS.saleRealEstate.root}${id}/`, params);
  }

  addSaleRealEstateFiles(params) {
    return this.post(API_ENDPOINTS.saleRealEstate.saleRealEstateFile.root, params);
  }

  deleteSaleRealEstateFile(id) {
    return this.delete(`${API_ENDPOINTS.saleRealEstate.saleRealEstateFile.root}${id}/`);
  }

  bulkDeleteSaleRealEstateFile(params) {
    return this.post(API_ENDPOINTS.saleRealEstate.saleRealEstateFile.bulkDeleteSaleRealEstateFile(), params);
  }

  addSaleRealEstateAttachments(params) {
    return this.post(API_ENDPOINTS.saleRealEstate.saleRealEstateAttachment.root, params);
  }

  deleteSaleRealEstateAttachment(id) {
    return this.delete(`${API_ENDPOINTS.saleRealEstate.saleRealEstateAttachment.root}${id}/`);
  }

  bulkDeleteSaleRealEstateAttachment(params) {
    return this.post(API_ENDPOINTS.saleRealEstate.saleRealEstateAttachment.bulkDeleteSaleRealEstateAttachment(), params);
  }
}

export default new SaleRealEstate();
