import axios from 'axios';

import ArcadaApiService from 'helpers/service/arcadaApiService';
import { HOST_API } from 'config';
import { PATH_PAGE, PATH_AUTH } from 'routes/paths';
import { setSession } from './jwt';

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

const defautlErrorMessage = { message: ['Ինչ որ բան սխալ է'] };

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error?.response || {};

    if (status >= 500 || error.code === 'ECONNABORTED') {
      window.location.href = PATH_PAGE.page500;
      return Promise.reject();
    }

    // if (status === 404) {
    //   window.location.href = PATH_PAGE.page404;
    //   return Promise.reject();
    // }

    if ([401, 403].includes(status)) {
      ArcadaApiService.auth.logout();
      setSession(null);
      localStorage.removeItem('user');
      localStorage.removeItem('accessToken');
      window.location.href = PATH_AUTH.login;
      return Promise.reject();
    }

    return Promise.reject((error.response && error.response.data) || defautlErrorMessage);
  }
);

export default axiosInstance;
