export const PAYMENT_STATUSES = {
  DEPOSITED: 1,
  DECLINED: 2,
  PENDING: 3,
  REFUND: 4,
  UNPAID: 5,
};

export const PAYMENT_TYPES = {
  CARD: 1,
  BINDING: 2,
  RECURRENT: 3,
  MANUAL: 5,
};

export const PAYMENT_TYPES_NAMING = {
  1: 'Քարտ',
  2: 'Քարտ',
  5: 'Ադմին',
};

export const PAYMENT_STATUSES_NAMING = {
  1: 'Վճարված',
  2: 'Մերժված',
  3: 'Ընթացքի մեջ',
  5: 'Չվճարված',
};

export const PAYMENT_STATUS_COLOR = {
  1: 'success',
  2: 'error',
  3: 'warning',
  5: 'error',
};

export const PAYMENT_TYPE_FILTER_OPTIONS = [
  { value: '1, 2', label: 'Քարտ' },
  { value: '5', label: 'Ադմին' },
];
