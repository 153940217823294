import { createSlice } from '@reduxjs/toolkit';
import ArcadaApiService from 'helpers/service/arcadaApiService';

import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { getFormData } from 'utils/formData';
import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';
import { TABLE_ROWS_COUNT_OPTIONS_MAX } from '../../utils/table';

const initialState = {
  isLoading: false,
  callRequest: null,
  callRequestOpenCount: 0,
  error: null,
};

const slice = createSlice({
  name: 'callRequest',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getEntitiesSuccess(state, action) {
      state.isLoading = false;
      state.callRequest = action.payload;
    },
    setCallRequestOpenCount(state, action) {
      state.callRequestOpenCount = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

export const { callRequestOpenCount } = slice.actions;

export function getCallRequest(skip = 0, take = TABLE_ROWS_COUNT_OPTIONS_MAX[0], search, filters) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await ArcadaApiService.callRequest.getCallRequest({ skip, take, search, ...filters });
      dispatch(slice.actions.getEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getCallRequestOpenCount() {
  return async () => {
    try {
      const { data } = await ArcadaApiService.callRequest.getCallRequestOpenCount();
      dispatch(slice.actions.setCallRequestOpenCount(data?.count));
    } catch (error) {
      console.log(error);
    }
  };
}

export function editCallRequest(data, id, skip, take, search, filters) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const formData = getFormData(data);

    try {
      await ArcadaApiService.callRequest.editCallRequest(formData, id);
      dispatch(getCallRequest(skip, take, search, filters));
      dispatch(getCallRequestOpenCount());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Զանգի հարցումը թարմացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function deleteCallRequest(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.callRequest.deleteCallRequest(id);
      dispatch(getCallRequest());
      dispatch(
        globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Զանգի հարցումը ապաակտիվացված է']))
      );
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}
