import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class Payment extends ApiService {
  getResidentTransactionsAll(params) {
    return this.get(`${API_ENDPOINTS.payment.transactionsAll()}`, params);
  }

  getResidentTransactionsDebts(params) {
    return this.get(`${API_ENDPOINTS.payment.transactionsDebts()}`, params);
  }

  getResidentTransactionsPaid(params, config) {
    return this.get(`${API_ENDPOINTS.payment.transactionsPaid()}`, params, config);
  }

  getActionsAll(params, config) {
    return this.get(`${API_ENDPOINTS.payment.actions()}`, params, config);
  }

  payDebtManual(params) {
    return this.post(`${API_ENDPOINTS.payment.paymentManual()}`, params);
  }

  paySurcharge(params) {
    return this.post(`${API_ENDPOINTS.payment.paymentSurcharge()}`, params);
  }

  refundDebtManual(params) {
    return this.post(`${API_ENDPOINTS.payment.refundManual()}`, params);
  }
}

export default new Payment();
