import { createSlice } from '@reduxjs/toolkit';
import { PAYMENT_STATUSES } from 'helpers/constants/payment';

import ArcadaApiService from 'helpers/service/arcadaApiService';
import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { TABLE_ROWS_COUNT_OPTIONS_MAX, TABLE_ROWS_COUNT_OPTIONS_MIN } from 'utils/table';

import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  isLoading: false,
  isLoadingTransactionsDebts: false,
  isLoadingPaySurcharge: false,
  residentTransactionsAll: null,
  residentTransactionsDebts: null,
  residentTransactionsPaid: null,
  paymenstExportData: null,
  error: null,
  errorPaySurcharge: null,
  depositList: null,
};

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadingTransactionsDebts(state) {
      state.isLoadingTransactionsDebts = true;
    },
    startLoadingPaySurcharge(state) {
      state.isLoadingPaySurcharge = true;
    },
    getTransactionsAllSuccess(state, action) {
      state.isLoading = false;
      state.residentTransactionsAll = action.payload;
    },
    getTransactionsSurchargeSuccess(state) {
      state.isLoadingPaySurcharge = false;
      state.errorPaySurcharge = null;
    },
    getTransactionsDebtsSuccess(state, action) {
      state.isLoadingTransactionsDebts = false;
      state.residentTransactionsDebts = action.payload;
    },
    getTransactionsPaidSuccess(state, action) {
      state.isLoading = false;
      state.residentTransactionsPaid = action.payload;
    },
    getDepositsSuccess(state, action) {
      state.isLoading = false;
      state.depositList = action.payload;
    },
    getEntitySuccess(state, action) {
      const newData = state.residentTransactionsAll.data.map((item) => {
        if (item.id === action.payload.id) item.status = action.payload.status;
        return item;
      });

      state.isLoading = false;
      state.residentTransactionsAll = { ...state.residentTransactionsAll, data: newData };
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    resetEntity(state) {
      state.residentTransactionsAll = null;
      state.error = null;
    },
  },
});

export default slice.reducer;

export function getResidentTransactionsAll(skip = 0, take = TABLE_ROWS_COUNT_OPTIONS_MIN[0], id, project) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } =
        (await ArcadaApiService.payment.getResidentTransactionsAll({ take, skip, user_id: id, project })) || {};
      dispatch(slice.actions.getTransactionsAllSuccess(data));
      dispatch(slice.actions.hasSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getResidentTransactionsDebts(id, project) {
  return async () => {
    dispatch(slice.actions.startLoadingTransactionsDebts());
    try {
      const { data } = (await ArcadaApiService.payment.getResidentTransactionsDebts({ user_id: id, project })) || {};
      dispatch(slice.actions.getTransactionsDebtsSuccess(data));
      dispatch(slice.actions.hasSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getDeposits(
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering = '-payment_date',
  search,
  filters
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } =
        (await ArcadaApiService.payment.getActionsAll({
          skip,
          take,
          ordering,
          search,
          ...filters,
        })) || {};

      dispatch(slice.actions.getDepositsSuccess(data));
      dispatch(slice.actions.hasSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getResidentTransactionsPaid(
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering = '-debt_date',
  search,
  filters
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } =
        (await ArcadaApiService.payment.getResidentTransactionsPaid({
          skip,
          take,
          ordering,
          search,
          ...filters,
        })) || {};

      dispatch(slice.actions.getTransactionsPaidSuccess(data));
      dispatch(slice.actions.hasSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function payDebtManual(debts, skip, take, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.payment.payDebtManual({ payment_debts: debts });
      dispatch(slice.actions.hasSuccess());
      dispatch(slice.actions.getEntitySuccess({ id: debts[0], status: PAYMENT_STATUSES.DEPOSITED }));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Գործարքը ընդունված է'])));
      dispatch(getResidentTransactionsAll(skip, take, id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function paySurcharge(id, skip, take, filterProject, { amount, project }) {
  return async () => {
    dispatch(slice.actions.startLoadingPaySurcharge());
    try {
      await ArcadaApiService.payment.paySurcharge({ user_id: id, amount, project });
      dispatch(slice.actions.getTransactionsSurchargeSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Գործարքը ընդունված է'])));
      dispatch(getResidentTransactionsAll(skip, take, id, filterProject));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function refundDebtManual(debts, skip, take, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.payment.refundDebtManual({ payments: debts });
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Գործարքը ընդունված է'])));
      dispatch(slice.actions.getEntitySuccess({ id: debts[0], status: PAYMENT_STATUSES.UNPAID }));
      dispatch(getResidentTransactionsAll(skip, take, id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function resetResidentTransactionsAll() {
  return () => {
    dispatch(slice.actions.resetEntity());
  };
}
